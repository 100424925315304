export const profileColumns = () => [
  {
    field: 'name',
    headerName: 'Etapa',
    minWidth: '100%',
    maxWidth: '100%',
    editable: false,
  },
]

export const TABLE_SX = {
  fontSize: '1.4rem',
  width: '100%',

  '& .MuiDataGrid-virtualScrollerRenderZone': {
    width: '100%',
  },

  '& .MuiDataGrid-row': {
    width: '100%',
  },

  '& .MuiDataGrid-cell': {
    width: '100%',
  },

  '& .MuiDataGrid-cell:hover': {
    cursor: 'pointer',
  },
}
