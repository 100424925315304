/* eslint-disable react/jsx-no-bind */
import React, { useMemo, useEffect, useCallback, Fragment, useState } from 'react'
import { Typography, Grid, IconButton } from '@material-ui/core'
import { useLocation, useParams, navigate } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { Preview, Button, Toast, Alert, Loader } from '@refera/ui-web'
import { Copyright } from '@refera/ui-icons'
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded'
import GppBadRoundedIcon from '@mui/icons-material/GppBadRounded'
import { Attachments } from '_/components/refera-ui'

import Header, { HEADER_THEME } from '_components/header'
import Footer from '_components/footer'
import Svg from '_components/svg'
import Loading from '_components/loading'
import CallDetails from '_assets/svgs/detalhes-do-chamado.svg'
import UserIcon from '_assets/svgs/user-icon.svg'
import AddressIcon from '_assets/svgs/address-icon.svg'
import { getServiceOrderByID } from '_modules/service-orders/selectors'
import { getServiceOrder, registerExecutionPostWork } from '_modules/service-orders/actions'
import { getBudgetByIdSelector } from '_modules/budget/selectors'
import { authenticateToken, getUser } from '_modules/authentication/actions'
import { formatDate } from '_/utils/helpers'
import { useDialog } from '_/hooks/use-dialog'

import useStyles from './styles'
import classnames from 'classnames'
import { userSelector } from '_/modules/authentication/selectors'
import { BUTTONS_ACTION } from '_/utils/constants/service-order'
import useCanDoButtonAction from '_/hooks/use-can-do-button-action'
import { getStepStatusLog } from '_/modules/budget/actions'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { FormHelperText, TextField } from '@mui/material'
import Textfield from '_/components/textfield'
import { useToast } from '_/hooks/use-toast'
import { USER_ROLES } from '_/utils/user'
import {
  WARRANTY_POLICY_LINK,
  REFERA_NO_WARRANTY_LINK,
  COVERAGE_WARRANTY_LINK,
  REFERA_COVERAGE_MANAGEMENT_LINK,
  REFERA_COVERAGE_LINK,
} from '_/utils/constants'

const defaultPreviewState = {
  visible: false,
  selectedIndex: undefined,
}

const toPercentage = value => parseFloat(value) / 100

const PostWork = () => {
  const dispatch = useDispatch()

  const { serviceOrderId } = useParams()

  const user = useSelector(userSelector)
  const { search } = useLocation()
  const verificationToken = search.split('verification_token=')[1]
  const serviceOrder = useSelector(state => getServiceOrderByID(state, serviceOrderId))
  const budget = useSelector(getBudgetByIdSelector(serviceOrder?.get('activeBudget')))
  const [previewState, setPreviewState] = useState(defaultPreviewState)
  const [isServiceOrderLoading, setIsServiceOrderLoading] = useState(true)
  const [isEditingForm, setIsEditingForm] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' })
  const styles = useStyles({ acceptsWarranty: budget?.acceptsWarranty })
  const { showDialog, closeDialog } = useDialog()

  const coverageValue = useMemo(() => budget?.coverageValue?.toJS(), [budget])
  const coverage = coverageValue?.coveragePercentage

  const canViewCoverage = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.VIEW_COVERAGE,
    isBudgetRelated: true,
  })

  const { showToast } = useToast()

  const isAdmin = useMemo(() => user?.activeProfile === USER_ROLES.REFERA, [user])
  const isIntermediary = useMemo(() => user?.activeProfile === USER_ROLES.INTERMEDIARY, [user])

  const methods = useForm({
    mode: 'all',
  })

  const { control, errors, handleSubmit } = methods

  const showInputs = useMemo(() => {
    if (!serviceOrder) return false

    return serviceOrder?.businessFront.get('name') === 'emergency'
  }, [serviceOrder])

  const saveServiceExecution = useCallback(
    async data => {
      setIsServiceOrderLoading(true)
      dispatch(
        registerExecutionPostWork(serviceOrder?.id, {
          ...data,
        })
      )
        .then(() => {
          showToast({ type: 'success' })
          loadBudget()
          setIsEditingForm(false)
          setIsServiceOrderLoading(false)
        })
        .catch(() => {
          showToast({ type: 'error' })
          setIsServiceOrderLoading(false)
        })
    },
    [serviceOrder?.id]
  )

  const applyPercentages = useCallback(
    value => {
      if (budget) {
        const {
          financialIncome,
          agencyCompletionPercentage: agencyPercentage,
          referaCompletionPercentage: referaPercentage,
        } = budget

        const partialValue =
          parseFloat(value) +
          (toPercentage(agencyPercentage) + toPercentage(referaPercentage)) * parseFloat(value)

        return parseFloat(partialValue) * toPercentage(financialIncome) + parseFloat(partialValue)
      }
      return null
    },
    [budget]
  )

  function CopyLinkToPostWorkPage() {
    const currentLink = window.location.href
    if (serviceOrder?.payerVerificationToken) {
      navigator.clipboard.writeText(
        `${currentLink}/?verification_token=${serviceOrder?.payerVerificationToken}`
      )
      setToast({
        isOpen: true,
        message: 'Conteúdo copiado para a área de transferência do dispositivo.',
        type: 'success',
      })
    }
  }

  const loadBudget = useCallback(async () => {
    if (verificationToken && !user?.id) {
      return dispatch(authenticateToken({ verificationToken }))
        .then(() => {
          dispatch(getServiceOrder(serviceOrderId))
            .then(() => {
              setIsServiceOrderLoading(false)
            })
            .catch(() => {
              return navigate('/link-expirado')
            })
        })
        .catch(() => {
          return navigate('/link-expirado')
        })
    }

    return dispatch(getServiceOrder(serviceOrderId))
      .then(() => {
        setIsServiceOrderLoading(false)
      })
      .catch(() => {
        navigate('/link-expirado')
      })
  }, [dispatch, verificationToken, serviceOrder, serviceOrderId, user?.id])

  const StyledShieldIcon = useMemo(() => {
    return function Icon() {
      return budget?.acceptsWarranty ? (
        <VerifiedUserRoundedIcon className={styles.shield} />
      ) : (
        <GppBadRoundedIcon className={styles.shield} />
      )
    }
  }, [budget?.acceptsWarranty])

  const StyledCopyright = () => <Copyright className={styles.copyrigth} />

  const getNoCoverageUrl = useMemo(() => {
    if (isAdmin || isIntermediary) {
      return REFERA_COVERAGE_MANAGEMENT_LINK
    }
    return REFERA_COVERAGE_LINK
  }, [isAdmin, isIntermediary])

  const handleLink = useMemo(
    () => (message, type) => {
      const url = (() => {
        switch (type) {
          case 'hasWarranty':
            return WARRANTY_POLICY_LINK
          case 'hasNoWarranty':
            return REFERA_NO_WARRANTY_LINK
          case 'hasCoverage':
            return COVERAGE_WARRANTY_LINK
          case 'hasNoCoverage':
            return getNoCoverageUrl
          default:
            return '#'
        }
      })()

      return (
        <a href={url} target="_blank" rel="noreferrer" className={styles.link}>
          {message}
        </a>
      )
    },
    [getNoCoverageUrl]
  )

  const handleWarrantyClick = useCallback(() => {
    let message

    if (budget?.acceptsWarranty) {
      message = (
        <span>
          Este serviço possui garantia. Caso precise de alguma assistência dentro do prazo de{' '}
          {budget?.warrantyMonths} meses, a partir da data de finalização do chamado, entre em
          contato com nosso suporte. Consulte nossa{' '}
          {handleLink('Política de garantias', 'hasWarranty')} para mais detalhes.
        </span>
      )
    } else {
      message = (
        <span>
          Este serviço não possui garantia. {handleLink('Saiba mais', 'hasNoWarranty')} sobre os
          casos que não damos garantia.
        </span>
      )
    }

    showDialog({
      customIcon: StyledShieldIcon,
      description: message,
      onApprove: closeDialog,
      labelApprove: 'Ok',
    })
  }, [budget, showDialog, closeDialog, StyledShieldIcon, handleLink])

  const coverageLimitValue = useMemo(() => {
    const value = budget?.priceTotal * (coverage * 0.01)
    return value?.toFixed(2).replace('.', ',')
  }, [coverage, budget?.priceTotal])

  const handleCopyrightClick = useCallback(() => {
    let message
    if (coverage > 0) {
      message = (
        <span>
          Este serviço possui a Cobertura da Refera! Não se preocupe. Caso o prestador não cumpra a
          garantia, a refera se compromete a resolver qualquer problema relacionado ao serviço
          executado, desde que esteja dentro do prazo da garantia e dentro do limite de valor{' '}
          {coverageLimitValue}. {handleLink('Clique aqui', 'hasCoverage')} para saber mais sobre
          essa política.
        </span>
      )
    } else if (!coverageValue) {
      message = (
        <span>
          Este serviço possui a Cobertura especial da Refera! A refera se compromete a resolver
          qualquer problema relacionado ao serviço executado, desde que esteja dentro do prazo da
          garantia. {handleLink('Clique aqui', 'hasNoCoverage')} para saber mais sobre essa
          política.
        </span>
      )
    }

    showDialog({
      customIcon: StyledCopyright,
      description: message,
      onApprove: closeDialog,
      labelApprove: 'Ok',
    })
  }, [budget, showDialog, closeDialog, StyledCopyright, coverageValue, handleLink])

  useEffect(() => {
    loadBudget()
  }, [])

  const getUserInfo = useCallback(async () => {
    if (user?.authToken && !user?.id) {
      await dispatch(getUser())
    }
  }, [dispatch, user?.token, user?.id])

  useEffect(() => {
    getUserInfo()
  }, [])

  useEffect(() => {
    dispatch(getStepStatusLog(serviceOrderId))
  }, [])

  const approbationDate = useMemo(() => {
    if (serviceOrder?.get('closedAt')) return formatDate(serviceOrder?.get('closedAt'))
    return ''
  }, [serviceOrder?.get('closedAt')])

  const formatAddress = useMemo(() => {
    const property = serviceOrder?.get('property')?.toJS()

    return `${property?.address ? `${property?.address}, ` : ''} ${
      property?.number ? property?.number : ''
    } - ${property?.neighborhood ? `${property?.neighborhood}` : ''}${
      property?.city ? `, ${property?.city}` : ''
    } ${property?.uf ? `, ${property?.uf}` : ''}`
  }, [serviceOrder])

  const renderProductList = useMemo(() => {
    const list = budget?.get('budgetItems')?.toJS()
    const total = applyPercentages(
      list?.reduce((result, currentValue) => result + currentValue.price, 0)
    )

    return (
      <Grid className={styles.productContainer}>
        <Typography className={styles.firstRow}>Descrição</Typography>
        <Typography className={styles.firstRow}>R$</Typography>
        {list?.map(product => {
          return (
            <Fragment key={product?.description}>
              <Typography className={styles.product}>{product?.description}</Typography>
              <Typography className={styles.product}>
                {applyPercentages(parseFloat(product?.price)).toFixed(2)}
              </Typography>
            </Fragment>
          )
        })}
        <Grid container className={styles.totalContainer}>
          <Typography className={styles.totalLabel}>Total</Typography>
          <Typography className={styles.total}>{parseFloat(total)?.toFixed(2)}</Typography>
        </Grid>
      </Grid>
    )
  }, [budget])

  const attachments = useMemo(() => {
    const postWorkPicturesList =
      serviceOrder
        ?.get('attachments')
        ?.toJS()
        ?.filter(file => file?.fileType === 'execution_proof')
        ?.map(file => ({ file: file?.file, id: file?.id })) || []

    const invoiceList =
      serviceOrder
        ?.get('attachments')
        ?.toJS()
        ?.filter(file => file?.fileType === 'invoice')
        ?.map(file => ({ file: file?.file, id: file?.id })) || []

    return { postWorkPicturesList, invoiceList }
  }, [serviceOrder])

  const PreviewComponent = useMemo(
    () => (
      <Preview
        open={previewState.visible}
        onClose={() => setPreviewState(defaultPreviewState)}
        selectedIndex={previewState.selectedIndex}
        images={attachments.postWorkPicturesList?.map((item, index) =>
          item.id
            ? {
                id: item.id,
                url: item.file,
              }
            : {
                id: index,
                url: URL.createObjectURL(item),
              }
        )}
      />
    ),
    [previewState, attachments]
  )

  const handleItemClick = useCallback(index => {
    setPreviewState(() => ({
      visible: true,
      selectedIndex: index,
    }))
  }, [])

  const handleCloseToast = () => {
    setToast(prevState => ({ ...prevState, isOpen: false }))
  }

  if (!serviceOrder) {
    return <Loading />
  }

  return (
    <Grid className={styles.container}>
      <Loader hasBackdrop open={isServiceOrderLoading} label="Aguarde..." />
      {PreviewComponent}
      <Header theme={HEADER_THEME.NO_BUTTONS} className={styles.header} />
      <Grid className={styles.introductionContainer}>
        <Svg icon={CallDetails} className={classnames(styles.image, styles.fullRow)} />
        <Typography className={classnames(styles.title, styles.fullRow)}>
          Seu serviço foi finalizado pela Refera ;)
        </Typography>
        {approbationDate && (
          <Typography className={classnames(styles.dateTime, styles.fullRow)}>
            {approbationDate}
          </Typography>
        )}
        {canViewCoverage && (
          <Grid className={classnames(styles.fullRow, styles.warrantyContent)}>
            <div className={classnames(styles.iconContent)}>
              <IconButton onClick={handleWarrantyClick}>
                <StyledShieldIcon />
              </IconButton>
              <Typography className={classnames(styles.warranty)}>
                {budget?.acceptsWarranty ? `${budget?.warrantyMonths}M` : 'Sem garantia'}
              </Typography>
            </div>
            {(!coverageValue || coverage > 0) && (
              <div>
                <IconButton onClick={handleCopyrightClick}>
                  <StyledCopyright />
                </IconButton>
              </div>
            )}
          </Grid>
        )}

        <Grid className={classnames(styles.card, styles.leftColumn)}>
          <Svg icon={UserIcon} className={styles.userIcon} />
          <Typography className={styles.cardTitle}>Dados do prestador</Typography>
          <Typography className={styles.cardContent}>
            {budget?.get('tradesman')?.toJS()?.companyName}
          </Typography>
        </Grid>

        <Grid className={classnames(styles.card, styles.rightColumn)}>
          <Svg icon={AddressIcon} className={styles.userIcon} />
          <Typography className={styles.cardTitle}>Endereço do imóvel</Typography>
          <Typography className={styles.cardContent}>{formatAddress}</Typography>
        </Grid>

        <Grid className={classnames(styles.section, styles.fullRow)}>
          <Typography className={styles.cardTitle}>Descrição do chamado</Typography>
          <Textfield
            className={styles.sectionContent}
            value={serviceOrder?.description}
            multiline
            InputProps={{
              disableUnderline: true,
            }}
            disabled
          />
        </Grid>

        <Typography className={classnames(styles.title, styles.fullRow, styles.budget)}>
          Orçamento
        </Typography>

        <Grid className={classnames(styles.section, styles.fullRow)}>
          <Typography className={styles.cardTitle}>Diagnóstico do problema</Typography>
          <Typography className={styles.sectionContent}>{budget?.get('diagnosis')}</Typography>
        </Grid>

        <Grid className={classnames(styles.section, styles.fullRow)}>
          <Typography className={styles.cardTitle}>Solução do problema</Typography>
          <Typography className={styles.sectionContent}>
            {budget?.get('proposedSolution')}
          </Typography>
        </Grid>

        <Grid className={classnames(styles.section, styles.fullRow)}>
          <Typography className={styles.cardTitle}>Orçamento detalhado</Typography>
          {renderProductList}
        </Grid>

        {showInputs && (
          <FormProvider {...methods}>
            <Typography className={classnames(styles.title, styles.fullRow, styles.budget)}>
              Execução
            </Typography>
            <form
              key="finishServiceModalForm"
              id="finishServiceModalForm"
              onSubmit={handleSubmit(saveServiceExecution)}
              className={classnames(styles.section, styles.fullRow, styles.form)}
            >
              <div className={styles.column}>
                {isAdmin ? (
                  <div className={styles.line}>
                    <h1 className={styles.cardTitle}>
                      Problema encontrado{' '}
                      {isEditingForm && <span className={styles.errorMessage}>*</span>}
                    </h1>
                    {!isEditingForm ? (
                      <Button
                        onClick={() => setIsEditingForm(true)}
                        className={styles.buttons}
                        variant="outlined"
                      >
                        Editar
                      </Button>
                    ) : (
                      <div>
                        <Button
                          className={styles.buttonRed}
                          variant="outlined"
                          onClick={() => setIsEditingForm(false)}
                        >
                          Cancelar
                        </Button>
                        <Button
                          className={styles.buttonSend}
                          type="submit"
                          color="primary"
                          variant="contained"
                          form="finishServiceModalForm"
                        >
                          Salvar
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <h1 className={styles.cardTitle}>
                    Problema encontrado{' '}
                    {isEditingForm && <span className={styles.errorMessage}>*</span>}
                  </h1>
                )}
                <Controller
                  control={control}
                  disabled={!isEditingForm}
                  defaultValue={serviceOrder?.issueFound ?? ''}
                  name="issueFound"
                  rules={{ required: { value: true, message: 'Este campo é obrigatório' } }}
                  render={field => {
                    return (
                      <TextField
                        className={styles.textArea}
                        id="issueFound"
                        placeholder="Descreva ao cliente qual o problema que ocorria no local."
                        InputLabelProps={{ shrink: true }}
                        multiline
                        error={!!errors?.issueFound}
                        maxRows={15}
                        minRows={3}
                        required
                        disabled={!isEditingForm}
                        InputProps={{ style: { fontSize: 16, borderRadius: 8 } }}
                        {...field}
                      />
                    )
                  }}
                />
                <FormHelperText className={styles.errorMessage} error={!!errors?.issueFound}>
                  {errors?.issueFound?.message}
                </FormHelperText>
              </div>

              <div className={styles.column}>
                <h1 className={styles.cardTitle}>
                  Solução realizada{' '}
                  {isEditingForm && <span className={styles.errorMessage}>*</span>}
                </h1>
                <Controller
                  control={control}
                  name="solutionApplied"
                  defaultValue={serviceOrder?.solutionApplied ?? ''}
                  disabled={!isEditingForm}
                  rules={{ required: { value: true, message: 'Este campo é obrigatório' } }}
                  render={field => {
                    return (
                      <TextField
                        className={styles.textArea}
                        id="solutionApplied"
                        placeholder="Descreva ao cliente o que você fez para solucionar o problema."
                        InputLabelProps={{ shrink: true }}
                        multiline
                        error={!!errors?.solutionApplied}
                        maxRows={15}
                        minRows={3}
                        required
                        disabled={!isEditingForm}
                        InputProps={{ style: { fontSize: 16, borderRadius: 8 } }}
                        {...field}
                      />
                    )
                  }}
                />
                <FormHelperText className={styles.errorMessage} error={!!errors?.solutionApplied}>
                  {errors?.solutionApplied?.message}
                </FormHelperText>
              </div>
            </form>
          </FormProvider>
        )}

        <Grid className={classnames(styles.section, styles.fullRow)}>
          <Typography className={styles.cardTitle}>Anexos</Typography>
          <Grid className={styles.postWorkAttach}>
            <Attachments
              label="Fotos do pós-obra"
              readOnly
              files={attachments.postWorkPicturesList}
              downloadable
              onItemClick={handleItemClick}
              accept={{
                'image/jpeg': [
                  '.jpeg',
                  '.png',
                  '.jpg',
                  '.bmp',
                  '.webp',
                  '.mkv',
                  '.mp4',
                  '.mov',
                  '.avi',
                  '.m4v',
                  '.mpg',
                  '.mpeg',
                  '.wmv',
                  '.webm',
                ],
              }}
            />
          </Grid>
          <Grid className={styles.postWorkAttach}>
            <Attachments
              label="Nota fiscal"
              readOnly
              files={attachments.invoiceList}
              content="files"
              downloadable
              onItemClick={index => window.open(attachments.invoiceList[index].file)}
              accept={{
                'application/pdf': ['.pdf'],
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Button onClick={CopyLinkToPostWorkPage} color="primary" variant="primary">
        Copiar link da tela
      </Button>
      <Toast draggable open={toast.isOpen} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert severity={toast.type} title={toast.message} onClose={handleCloseToast} />
      </Toast>
      <Footer className={styles.footer} />
    </Grid>
  )
}

export default PostWork
