import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useToast } from '_/hooks/use-toast'

import { Grid } from '@mui/material'
import { Datagrid } from '@refera/ui-web'

import HeaderTitle from '_/components/header-title'

import useStyles from './styles'

import { profileColumns, TABLE_SX } from './constants'
import { navigate, useParams } from '@reach/router'
import { ROUTES } from '_/utils/constants'
// import useRolePermission from '_/hooks/use-role-permission'
// import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import { agencySelector, getMacroStepsListSelector } from '_/modules/agency/selectors'
import { getAgencyFull, getMacroSteps } from '_/modules/agency/actions'

export function MacroSteps() {
  const dispatch = useDispatch()
  const styles = useStyles()
  const { agencyId } = useParams()

  const agency = useSelector(agencySelector)

  // const { checkIfUserDoesNotPermission } = useRolePermission()

  const [isLoading, setIsLoading] = useState(false)

  const macroSteps = useSelector(getMacroStepsListSelector)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [orderBy, setOrderBy] = useState('desc')
  const [formStateDefault, setFormStateDefault] = useState(macroSteps?.results || [])

  const { showToast } = useToast()

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('name')
        return
      }
      const { field, sort } = order
      sort === 'desc' ? setOrderBy(`-${field}`) : setOrderBy(field)
    },
    [setOrderBy]
  )

  const getMacroStepList = useCallback(() => {
    setIsLoading(true)

    return dispatch(
      getMacroSteps({
        contractType: agency?.contractType,
        pageSize,
        page: page + 1,
        orderBy,
      })
    )
      .then(data => {
        setFormStateDefault(data?.results || [])
        return setIsLoading(false)
      })
      .catch(() => {
        showToast({ type: 'error', message: 'Ocorreu um erro ao buscar as macro etapas.' })
        return setIsLoading(false)
      })
  }, [dispatch, orderBy, page, pageSize, agency])

  const handleCellClick = useCallback(({ row }) => {
    // if (
    //   checkIfUserDoesNotPermission(PERMISSIONS.PERMISSION_GROUPS, [
    //     PERMISSIONS_ACTIONS.ADD,
    //     PERMISSIONS_ACTIONS.EDIT,
    //   ])
    // ) {
    //   return
    // }
    return navigate(`${ROUTES.AGENCY}/${agencyId}${ROUTES.MACRO_STEPS}/${row?.id}`)
  }, [])

  const goBack = () => {
    window?.history?.length <= 2 ? navigate(`/`) : navigate(-1)
  }

  const handleGetAgencyInfo = useCallback(() => {
    if (agencyId) {
      dispatch(getAgencyFull(agencyId)).catch(() => {
        showToast({
          message: 'Erro ao buscar dados da intermediária.',
          type: 'error',
        })
      })
    }
  }, [agencyId])

  useEffect(() => {
    if (!agency?.contractType) {
      handleGetAgencyInfo()
    }
  }, [agencyId, agency])

  useEffect(() => {
    if (agency?.contractType) {
      getMacroStepList()
    }
  }, [orderBy, page, pageSize, agency])

  return (
    <Grid className={styles.page}>
      <HeaderTitle title="Etapas para clientes" backButtonAction={goBack} />
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
            }}
            loading={isLoading}
            rows={formStateDefault}
            columns={profileColumns()}
            onCellClick={handleCellClick}
            rowCount={macroSteps?.count || 0}
            sx={TABLE_SX}
            noRowsLabel="Nenhum resultado encontrado"
            paginationMode="server"
            onPageChange={setPage}
            page={page}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            onSortModelChange={handleOrderBy}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
