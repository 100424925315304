import { Loader } from '@refera/ui-web'

import React, { useCallback, useEffect, useState } from 'react'
import HeaderTitle from '_/components/header-title'
import { Grid, Button } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useToast } from '_/hooks/use-toast'
import { navigate, useParams } from '@reach/router'
import useStyles from './styles'
import { BasicInput, Checkbox } from '_/components/inputs'
import { useDispatch } from 'react-redux'
import ModalDialog, { WARNING_MODAL } from '_/components/modal/modal-dialog'
import { createSlug } from '_/utils/permissions'
import {
  editMacroStep,
  getMacroStepById,
  getMacroStepsCustom,
  postMacroStep,
} from '_/modules/agency/actions'

export default function MacroStepForm() {
  const dispatch = useDispatch()
  const styles = useStyles()
  const [defaultMacroStep, setEditMacroStep] = useState(null)

  // const { checkIfUserDoesNotPermission } = useRolePermission()

  const [modalDialog, setModalDialog] = useState({
    isOpen: false,
    textOkButton: 'Ok, entendi',
    subTitle: `Salve antes as edições deste registro.`,
    type: WARNING_MODAL,
  })

  const { macroStepId, agencyId } = useParams()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { showToast } = useToast()

  // Form constants
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      tip: '',
      alert: '',
      expectedTime: '',
      requiredTip: false,
      requiredAlert: false,
      requiredExpectedTime: false,
    },
  })

  const { handleSubmit, reset, setValue, watch } = methods

  const nameMacroStep = watch('nameDefault')
  const requiredTip = watch('requiredTip')
  const requiredAlert = watch('requiredAlert')
  const requiredExpectedTime = watch('requiredExpectedTime')

  const resetFields = useCallback(() => {
    if (!requiredTip) {
      setValue('tip', null)
    }

    if (!requiredAlert) {
      setValue('alert', null)
    }

    if (!requiredExpectedTime) {
      setValue('expectedTime', '')
    }
  }, [requiredTip, requiredAlert, requiredExpectedTime])

  useEffect(() => {
    resetFields()
  }, [requiredTip, requiredAlert, requiredExpectedTime])

  const goBack = () => {
    navigate(-1)
  }

  const macroStageEstimatedTimeText = useCallback(estimatedTime => {
    const macroStageEstimatedTime = Math.ceil(estimatedTime ?? 0)
    if (macroStageEstimatedTime < 8) {
      if (macroStageEstimatedTime === 1) {
        return '1 hora útil'
      }
      return `${macroStageEstimatedTime} horas úteis`
    }
    const countDays = Math.ceil(macroStageEstimatedTime / 8)
    if (countDays === 1) {
      return '1 dia útil'
    }
    return `${countDays} dias úteis`
  }, [])

  const handleSubmitForm = useCallback(
    data => {
      const newData = {
        ...data,
        name: createSlug(data.desc),
      }

      if (defaultMacroStep?.id) {
        // if (checkIfUserDoesNotPermission(PERMISSIONS.PERMISSION_GROUPS, PERMISSIONS_ACTIONS.ADD)) {
        //   return null
        // }

        return dispatch(
          editMacroStep(defaultMacroStep?.id, {
            expectedTime: newData?.expectedTime || null,
            alert: newData?.alert || null,
            tip: newData?.tip || null,
          })
        )
          .then(() => {
            getMacroStep()
            showToast({ type: 'success' })
          })
          .catch(() => {
            return showToast({
              type: 'error',
              message: 'Ocorreu um erro ao editar macro etapa.',
            })
          })
      }

      return dispatch(
        postMacroStep({
          expectedTime: newData?.expectedTime || null,
          alert: newData?.alert || null,
          tip: newData?.tip || null,
          agency: agencyId,
          stepNameForCustomer: macroStepId,
        })
      )
        .then(() => {
          getMacroStep()
          showToast({ type: 'success' })
        })
        .catch(() => {
          return showToast({
            type: 'error',
            message: 'Ocorreu um erro ao editar macro etapa.',
          })
        })
    },
    [agencyId, macroStepId, defaultMacroStep]
  )

  const getMacroStep = useCallback(() => {
    if (macroStepId) {
      setIsSubmitting(true)

      return dispatch(getMacroStepById(macroStepId))
        .then(data => {
          setValue('nameDefault', data?.name)
          setValue('expectedTimeDefault', macroStageEstimatedTimeText(data?.expectedTime))
          setValue('alertDefault', data?.alert)
          setValue('tipDefault', data?.tip)
          dispatch(
            getMacroStepsCustom({ agency: agencyId, stepNameForCustomer: macroStepId })
          ).then(dataCustom => {
            setEditMacroStep(dataCustom?.results?.[0])
            setValue('name', dataCustom?.results?.[0]?.name)
            setValue('expectedTime', dataCustom?.results?.[0]?.expectedTime)
            setValue('alert', dataCustom?.results?.[0]?.alert)
            setValue('tip', dataCustom?.results?.[0]?.tip)

            setValue('requiredTip', !!dataCustom?.results?.[0]?.tip)
            setValue('requiredAlert', !!dataCustom?.results?.[0]?.alert)
            setValue('requiredExpectedTime', !!dataCustom?.results?.[0]?.expectedTime)
          })
          return setIsSubmitting(false)
        })
        .catch(() => {
          showToast({ type: 'error', message: 'Ocorreu um erro ao buscar macro etapa.' })
          return setIsSubmitting(false)
        })
    }
    return null
  }, [dispatch, macroStepId, agencyId])

  function handleReset() {
    if (macroStepId) {
      getMacroStep()
    }

    return reset()
  }

  useEffect(() => {
    getMacroStep()
  }, [macroStepId, agencyId])

  return (
    <Grid className={styles.app}>
      <HeaderTitle title={`Etapas para clientes/${nameMacroStep}`} backButtonAction={goBack}>
        <Grid style={{ display: 'flex', gap: '8px' }}>
          <Button className={[styles.buttonRed, styles.button]} onClick={() => handleReset()}>
            Cancelar
          </Button>
          <Button
            className={[styles.buttonFullBlue, styles.button]}
            form="edit-macro-step"
            onClick={handleSubmit(handleSubmitForm)}
          >
            Salvar
          </Button>
        </Grid>
      </HeaderTitle>
      <FormProvider {...methods}>
        <form
          id="edit-macro-step"
          onSubmit={handleSubmit(handleSubmitForm)}
          className={styles.container}
        >
          <BasicInput
            label="Nome da etapa"
            name="nameDefault"
            disabled
            containerClasses={styles.input}
            labelClasses={styles.label}
          />

          <BasicInput
            label="Horas úteis na etapa"
            name="expectedTimeDefault"
            disabled
            multiline
            containerClasses={styles.input}
            labelClasses={styles.label}
          />

          <BasicInput
            label="Explicação sobre a etapa"
            name="tipDefault"
            disabled
            multiline
            containerClasses={styles.input}
            labelClasses={styles.label}
          />

          <BasicInput
            label="Alerta sobre a etapa"
            name="alertDefault"
            disabled
            containerClasses={styles.input}
            labelClasses={styles.label}
          />

          <div className={styles.form}>
            <div className={styles.field}>
              <BasicInput
                label="Horas úteis na etapa"
                name="expectedTime"
                disabled={!requiredExpectedTime}
                containerClasses={styles.input}
                labelClasses={styles.label}
                showErrorMessage={false}
                type="number"
              />
              <Checkbox label="Quero personalizar" name="requiredExpectedTime" />
            </div>

            <div className={styles.field}>
              <BasicInput
                label="Explicação sobre a etapa"
                name="tip"
                disabled={!requiredTip}
                containerClasses={styles.input}
                labelClasses={styles.label}
                showErrorMessage={false}
              />
              <Checkbox label="Quero personalizar" name="requiredTip" />
            </div>

            <div className={styles.field}>
              <BasicInput
                label="Alerta sobre a etapa"
                name="alert"
                disabled={!requiredAlert}
                containerClasses={styles.input}
                labelClasses={styles.label}
                showErrorMessage={false}
              />
              <Checkbox label="Quero personalizar" name="requiredAlert" />
            </div>
          </div>
        </form>
      </FormProvider>

      <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />
      <Loader hasBackdrop open={isSubmitting} label="Aguarde..." />
    </Grid>
  )
}
